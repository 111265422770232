import { CSharpGenerator } from "./CSharpGenerator";
import { MassTransitGenerator } from "./MassTransitGenerator";
import { TypeScriptGenerator, GoGenerator } from '@asyncapi/modelina';

const OPENAPI = "openapi";
const ASYNCAPI = "asyncapi";

type Generator = CSharpGenerator | MassTransitGenerator | TypeScriptGenerator | GoGenerator;

type AvailableGenerator = {
    name: string;
    title: string;
    helptext: string;
    lang: string;
    types: string[];
    factory: () => Generator;
}

export class GeneratorFactory {

    generators: AvailableGenerator[] = []

    constructor() {
        this.generators.push({
            name: "csharp_mass_transit",
            title: "C# with MassTransit",
            helptext: "",
            lang: "csharp",
            types: [ASYNCAPI],
            factory: () => new MassTransitGenerator()
        });

        this.generators.push({
            name: "csharp",
            title: "C#",
            helptext: "",
            lang: "csharp",
            types: [OPENAPI, ASYNCAPI],
            factory: () => new CSharpGenerator()
        });

        this.generators.push({
            name: "typescript",
            title: "TypeScript",
            helptext: "",
            lang: "typescript",
            types: [OPENAPI, ASYNCAPI],
            factory: () => new TypeScriptGenerator()
        });

        this.generators.push({
            name: "go",
            title: "Go",
            helptext: "",
            lang: "go",
            types: [OPENAPI, ASYNCAPI],
            factory: () => new GoGenerator()
        });
    }

    availableTargets(type: string) {
        return this.generators
            .filter(g => g.types.includes(type))
            .map(g => { return { 
                label: g.title, 
                value: g.name }
            });
    }

    createGenerator(target: string) {
        let generator = this.generators.find(g => g.name == target);
        
        if(!generator) {
            generator = this.generators[0];
        }

        return { generator: generator.factory(), lang: generator.lang };
    }
}