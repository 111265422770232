import { ParseOutput, Parser } from '@asyncapi/parser';
import { InputMetaModel, ObjectModel, OutputModel, ReferenceModel } from "@asyncapi/modelina";
import { CSharpGenerator } from "./CSharpGenerator";

export class MassTransitGenerator {
  private _generator: CSharpGenerator;

  constructor() {
    this._generator = new CSharpGenerator();
  }

  async generate(input: any | InputMetaModel): Promise<OutputModel[]> {
    const models = await this._generator.generate(input);

    const parser = new Parser();
    const spec = await parser.parse(input);
  
    let inputModel = models[0].inputModel;

    let newInputModel = new InputMetaModel();

    for(const modelKey of Object.keys(inputModel.models)) {
      const model = inputModel.models[modelKey] as ObjectModel;

      if(!model) {
        console.debug("Not an object model: " + modelKey);
        continue;
      }
      
      if(model.properties && model.properties.message) {
        let payloadModel = model.properties.message.property;
        let referenceModel = payloadModel as ReferenceModel;
        
        if(referenceModel) {
          // Inherit the name of the containing schema (ie. the one with the message property)
          referenceModel.ref.name = model.name;

          referenceModel.ref.originalInput.namespace = this.findNamespace(model.name, spec);

          newInputModel.models[model.name] = referenceModel.ref;
        }
      } else if(!model.name.startsWith("anonymous")) {
        // Model is referenced from one of the schemas.
        // Not including anonymous schemas.
        newInputModel.models[model.name] = model;
      }
    }

    return await this._generator.generate(newInputModel);
  }

  private findNamespace(modelName: string, spec: ParseOutput) {
    let message = spec.document?.messages().find(m => m.name() == modelName);
    if (!message)
      return;
    
    let channel = message.channels().at(0);
    if (!channel)
      return;
    
    let address = channel.address();
    let index = address.indexOf(":");
    if (index >= 0) {
      address = address.slice(0, index);
    }

    return address;
  }
}