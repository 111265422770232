import { ApiEntity } from '@backstage/catalog-model';
import { useEntity } from '@backstage/plugin-catalog-react';
import { Alert } from '@material-ui/lab';
import React from 'react';
import { Typography, Grid } from '@material-ui/core';
import {
  InfoCard,
  Page
} from '@backstage/core-components';
import { GeneratorComponent } from '../GeneratorComponent';

export const EntityApiGenerationCard = () => {
  const { entity } = useEntity<ApiEntity>();

  if (!entity) {
    return <Alert severity="error">Could not fetch the API</Alert>;
  }

  return (
    <Page themeId="tool">
    
        <Grid container spacing={3} direction="column">
          <Grid item>
            <InfoCard title="About generated models">
              <Typography variant="body1">
                This generator helps you to generate data models for multiple programming languages and frameworks
                from the API schema and specification.

                Please copy the generated code out of the page and into your own project, where you maintain it
                as source code together with the rest of your application.
              </Typography>
            </InfoCard>
          </Grid>
          <Grid item>
            <GeneratorComponent spec={entity.spec} />
          </Grid>
        </Grid>
    </Page>
  )
};
