import React from 'react';
import YAML from 'yaml';
import { useAsync } from 'react-use';
import { CodeSnippet, Progress, Select, TabbedLayout } from '@backstage/core-components';
import { Box, Container, Divider, Paper } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { GeneratorFactory } from './generators';

type ApiSpecification = {
  type: string;
  definition: string;
};

export type GeneratorComponentProps = {
  spec: ApiSpecification;
};

type TargetSelectorProps = {
  onChange: ((target: string) => any);
  specType: string;
}

type GeneratorProps = {
  target: string;
}

const generatorFactory = new GeneratorFactory();

const TargetSelector = ({ onChange, specType }: TargetSelectorProps) => {
  const availableTargets = generatorFactory.availableTargets(specType);

  return <Select label='Target language'
            items={availableTargets} 
            placeholder='Select target language' 
            onChange={(value) => {onChange(value.toString())}} />
}

const Generator = (props: GeneratorComponentProps & GeneratorProps) => {

  const { loading, value, error } = useAsync(async () => {

    const { generator, lang } = generatorFactory.createGenerator(props.target);
    const result = await generator.generate(YAML.parse(props.spec.definition));

    return result.map(r => (
      <TabbedLayout.Route path={r.modelName} title={r.modelName}>
        <CodeSnippet text={r.result} language={lang} showCopyCodeButton />
      </TabbedLayout.Route>
    ));

  }, [props.target]);

  if(!props.target || props.target.length == 0) {
    return <Alert>Please choose a target</Alert>
  }

  if (loading) {
    return <Progress>Please wait</Progress>
  }
  if (error) {
    return <Alert>{error.message}</Alert>
  }

  return <TabbedLayout children={value} />
}

export const GeneratorComponent = ({ spec }: GeneratorComponentProps) => {

  const [target, setTarget] = React.useState('');

  return (
    <Paper>
     <Container disableGutters>
        <Box paddingTop={2} paddingBottom={2} paddingLeft={2}>
          <TargetSelector specType={spec.type} onChange={setTarget} />
        </Box>

        <Divider />

        <Generator spec={spec} target={target} />
       </Container>
    </Paper>
  )
};
