import React, { useState } from 'react';
import { Button, Typography, Box } from '@material-ui/core';
import { useEntity } from '@backstage/plugin-catalog-react';
import { useApi, configApiRef } from '@backstage/core-plugin-api';

export const CatalogComponent = () => {
    const { entity } = useEntity();
    const config = useApi(configApiRef);
    const [isCopied, setIsCopied] = useState(false);
    const uri = config.getConfig('backend').getString('baseUrl') + "/api/api-catalog/" + entity.metadata.name;

    const handleCopyClick = () => {
        copyTextToClipboard(uri)
            .then(() => {
                setIsCopied(true);
                setTimeout(() => {
                    setIsCopied(false);
                }, 1500);
            })
            .catch((err) => {
                console.log(err);
            });
    }

    return (
        <Box >
            <Typography variant="subtitle1" display="block" gutterBottom>Specification URI:</Typography>
            <Typography color='primary' variant="body2" display="block" gutterBottom>{uri}</Typography>
            <Box justifyContent="space-between" display="flex">
                <Button variant="contained" color="primary"
                    onClick={handleCopyClick}>
                    {isCopied ? 'Copied!' : 'Copy URI'}</Button>
                <Button variant="contained" color="primary" target="_blank"
                    href={uri} >Get Raw Specification</Button>
            </Box>
        </Box>
    )
};

async function copyTextToClipboard(text: string) {
    if ('clipboard' in navigator)
        return await navigator.clipboard.writeText(text);
}
