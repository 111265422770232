import { ConstrainedStringModel, ConstrainedFloatModel, ConstrainedMetaModel, CSharpGenerator as InnerCSharpGenerator, CSHARP_DEFAULT_PRESET, InputMetaModel, OutputModel, PropertyArgs } from '@asyncapi/modelina';
import { AbstractDependencyManager } from '@asyncapi/modelina/lib/types/generators/AbstractDependencyManager';

let mapStringModelFormatToType = function(ctx: PropertyArgs, format: string, type: string, namespace: string, dependencyManager: AbstractDependencyManager) {

  if (!(ctx.property.property instanceof ConstrainedStringModel))
    return;

  mapModelFormatToType(ctx, format, type, namespace, dependencyManager);
}

let mapFloatModelFormatToType = function(ctx: PropertyArgs, format: string, type: string, namespace: string, dependencyManager: AbstractDependencyManager) {

  if (!(ctx.property.property instanceof ConstrainedFloatModel))
    return;

  mapModelFormatToType(ctx, format, type, namespace, dependencyManager);
}

let mapModelFormatToType = function(ctx: PropertyArgs, format: string, type: string, namespace: string, dependencyManager: AbstractDependencyManager) {

  let model = ctx.property.property as ConstrainedMetaModel;

  if (!model)
    return;

  if (!model.originalInput.format)
    return;

  if (model.originalInput.format != format)
    return;

  dependencyManager.addDependency(`using ${namespace};`);

  let typeSuffix = ctx.property.property.type.endsWith("?") ? "?" : "";
  ctx.property.property.type = type + typeSuffix;
}

export class CSharpGenerator {
  private _generator: InnerCSharpGenerator;

  constructor() {
    this._generator = new InnerCSharpGenerator({
      presets: [
        {
          preset: CSHARP_DEFAULT_PRESET,
          options: {
            autoImplementedProperties: true
          },
        },
        {
          class: {
            property(ctx) {
              mapStringModelFormatToType(ctx, "date", "DateOnly", "System", ctx.renderer.dependencyManager);
              mapStringModelFormatToType(ctx, "date-time", "DateTime", "System", ctx.renderer.dependencyManager);
              mapFloatModelFormatToType(ctx, "decimal", "decimal", "System", ctx.renderer.dependencyManager);
              return ctx.content;
            } 
          }
        },
        {
          class: {
            self(ctx) {
              if (ctx.model.originalInput.namespace) {
                const content = ctx.renderer.indent(ctx.content);
                const namespace = ctx.model.originalInput.namespace;

                return `namespace ${namespace}\n{\n${content}\n}`;  
              } else {
                return ctx.content;
              }
            }
          }
        }
      ],
      collectionType: 'List'
    });
  }

  generate(input: any | InputMetaModel): Promise<OutputModel[]> {
    return this._generator.generate(input);
  }
}